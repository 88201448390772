import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputMaskModule, createMask } from '@ngneat/input-mask';
import { Guid } from 'guid-ts';
import { InViewportModule } from 'ng-in-viewport';
import { Observable, Subscriber, Subscription, combineLatest, from, of, switchMap, timer } from 'rxjs';
import { catchError, filter, map, skip, take } from 'rxjs/operators';

import { groupOptions } from '@c/trading-log/shared/add-trading-log-trade/add-trading-log-trade.data';
import {
  GroupOptionType,
  IAddTradingLogTradeData,
  IExtendedTradingLogGroup,
} from '@c/trading-log/shared/add-trading-log-trade/add-trading-log-trade.model';
import { MomentDateTimeFormats, TradingLogNotDefinedAccountId } from '@const';
import { AutoFocusAndSelectDirective } from '@core/directives/autofocus-and-select/autofocus-and-select.directive';
import { ErrorMessageComponent } from '@m/common/error-message/error-message.component';
import {
  TLBathTransactionsCommand,
  TLRequestTransactionsCommand,
  TradingLogGroupModel,
  TradingLogTransactionModel,
} from '@mod/trading-log';
import { DialogsService } from '@s/common';
import { TradingLogLogicService } from '@s/trading-log';
import { TradingLogStreamingService } from '@s/trading-log/trading-log-streaming.service';
import { TradingLogGroupType, TradingLogTransactionType } from '@t/trading-log';
import { getDateComparerDesc, getStringComparer } from '@u/comparers';
import { convertArrayToMap, round } from '@u/utils';
import moment from 'moment';

@Component({
  standalone: true,
  selector: 'app-add-trading-log-trade',
  templateUrl: './add-trading-log-trade.component.html',
  styleUrls: ['./add-trading-log-trade.component.scss'],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatTooltipModule,
    FormsModule,
    InputMaskModule,
    ErrorMessageComponent,
    MatRippleModule,
    InViewportModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    AutoFocusAndSelectDirective,
  ],
  providers: [TradingLogLogicService],
})
export class AddTradingLogTradeComponent implements OnInit, OnDestroy {
  @Input() set transactionDetails(value: IAddTradingLogTradeData) {
    this.data = value;

    this.qty = this.data.qty ?? null;
    this.price = this.data.price ?? null;
    this.selectedTransactionType = this.data.type ?? null;

    this.updateGroupsLists();
  }

  @Input() set availableTransactionTypes(value: TradingLogTransactionType[]) {
    this.allowedTransactionTypes = value;
  }

  @Input() set specificValuesForAvailableTransactionTypes(
    value: Map<TradingLogTransactionType, IAddTradingLogTradeData>,
  ) {
    this.specificValuesForTransactionTypes = value;
  }

  @Input() canSwitchTransactionType = true;

  @Output() closeMenu = new EventEmitter<void>();

  @Output() contentResized = new EventEmitter<void>();

  protected premiumInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 2,
    min: 0,
    max: 999.99,
    autoUnmask: true,
    unmaskAsNumber: true,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0.00',
  });

  protected sharesInputMask = createMask({
    alias: 'numeric',
    groupSeparator: ',',
    digits: 0,
    min: 0,
    max: 999999,
    autoUnmask: true,
    unmaskAsNumber: true,
    digitsOptional: false,
    showMaskOnHover: false,
    rightAlign: false,
    allowMinus: false,
    placeholder: '0',
  });

  protected data: Partial<IAddTradingLogTradeData> = {};
  protected allowedTransactionTypes: TradingLogTransactionType[] = [];
  protected transactionTypes = TradingLogTransactionType;
  protected specificValuesForTransactionTypes: Map<TradingLogTransactionType, IAddTradingLogTradeData> | null = null;

  protected isGroupsListTopVisible = true;
  protected isGroupsListBottomVisible = true;

  protected isError = false;

  protected qty: number | null = null;
  protected price: number | null = null;

  protected selectedTransactionType: TradingLogTransactionType = null;
  protected searchString = '';
  protected selectedGroup: TradingLogGroupModel | null = null;
  protected showArchivedTrades = true;
  protected showActiveTrades = true;
  protected currentGroupOptionValue: GroupOptionType = groupOptions.New;

  protected allGroups: ReadonlyArray<IExtendedTradingLogGroup> = [];
  protected activeGroups: ReadonlyArray<IExtendedTradingLogGroup> = [];
  protected archivedGroups: ReadonlyArray<IExtendedTradingLogGroup> = [];
  protected filteredActiveGroups: ReadonlyArray<IExtendedTradingLogGroup> = [];
  protected filteredArchivedGroups: ReadonlyArray<IExtendedTradingLogGroup> = [];

  protected readonly notDefinedId = null;
  // use same sort as for accounts/strategies in trades-group-header-form
  protected readonly accounts$ = this.tradingLogStreamingService.accountsLoaded$.pipe(
    filter((isAccountsLoaded) => isAccountsLoaded),
    switchMap(() => this.tradingLogStreamingService.accounts$),
    map((accounts) => {
      return [...accounts]
        .filter((item) => item.id !== TradingLogNotDefinedAccountId)
        .sort(getStringComparer((a) => a.name));
    }),
  );
  protected readonly strategies$ = this.tradingLogStreamingService.strategiesLoaded$.pipe(
    filter((isStrategiesLoaded) => isStrategiesLoaded),
    switchMap(() => this.tradingLogStreamingService.strategies$),
    map((strategies) => {
      return [
        ...[...strategies.filter((st) => st.is_default)].sort(getStringComparer((a) => a.name)),
        ...[...strategies.filter((st) => !st.is_default)].sort(getStringComparer((a) => a.name)),
      ];
    }),
  );

  protected selectedAccount: string = this.notDefinedId;
  protected selectedStrategy: string = this.notDefinedId;

  protected readonly groupOptions = groupOptions;
  protected readonly tradingLogTransactionTypesFullNames = this.getTradingLogTransactionTypesFullNames();

  protected readonly showFieldsMap: Record<TradingLogTransactionType, boolean> = {
    [TradingLogTransactionType.BuyCall]: true,
    [TradingLogTransactionType.BuyPut]: true,
    [TradingLogTransactionType.SellCall]: true,
    [TradingLogTransactionType.SellPut]: true,
    [TradingLogTransactionType.Dividends]: false,
    [TradingLogTransactionType.BuyStock]: false,
    [TradingLogTransactionType.SellStock]: false,
  };

  private readonly showProgressMinTimeMs = 1000;

  private subscriptions = new Subscriber();
  private groupsSubscription: Subscription | null = null;

  constructor(
    private tradingLogStreamingService: TradingLogStreamingService,
    private dialogsService: DialogsService,
    private tradingLogLogicService: TradingLogLogicService,
  ) {}

  ngOnInit(): void {
    this.initContent();
  }

  ngOnDestroy(): void {
    this.resetComponentState();

    if (this.groupsSubscription) {
      this.groupsSubscription.unsubscribe();
      this.groupsSubscription = null;
    }

    this.subscriptions.unsubscribe();
  }

  protected close(): void {
    this.closeMenu.emit();
  }

  protected initContent(): void {
    this.groupsSubscription = combineLatest([
      this.tradingLogStreamingService.groupsLoaded$,
      this.tradingLogStreamingService.accountsLoaded$,
      this.tradingLogStreamingService.strategiesLoaded$,
    ])
      .pipe(
        filter(
          ([isGroupsLoaded, isAccountsLoaded, isStrategiesLoaded]) =>
            isGroupsLoaded && isAccountsLoaded && isStrategiesLoaded,
        ),
        switchMap(() =>
          combineLatest([
            this.tradingLogStreamingService.groups$,
            this.tradingLogStreamingService.summaryMap$,
            this.tradingLogStreamingService.accounts$.pipe(map((value) => convertArrayToMap(value, 'id'))),
            this.tradingLogStreamingService.strategies$.pipe(map((value) => convertArrayToMap(value, 'id'))),
          ]),
        ),
      )
      .subscribe(([groups, summary, accountsMap, strategiesMap]) => {
        const extendedGroups = groups.map(({ id, symbol, type, account_id, strategy_id }) => {
          return {
            id,
            symbol: symbol?.trim() ?? '',
            type,
            account_name: accountsMap.get(account_id)?.name ?? '',
            strategy_name: strategiesMap.get(strategy_id)?.name ?? '',
            min_date: summary.get(id)?.min_date ?? null,
            max_date: summary.get(id)?.max_date ?? null,
          };
        });

        this.allGroups = this.sortGroups(extendedGroups);
        this.updateGroupsLists();
      });

    this.currentGroupOptionValue = this.groupOptions.New;
    this.updateGroupsLists();
  }

  protected addNewTransaction(): void {
    this.isError = false;
    const progressDialogRef = this.dialogsService.openPlaceTradeStatusModal({ status: 'progress' });

    if (this.currentGroupOptionValue === groupOptions.Existing && this.selectedGroup) {
      combineLatest([
        this.createTransaction(this.selectedGroup),
        timer(0, this.showProgressMinTimeMs).pipe(skip(1), take(1)),
      ])
        .pipe(
          catchError(() => {
            progressDialogRef.close();
            this.isError = true;

            return of(null);
          }),
        )
        .subscribe(() => {
          this.closeMenu.emit();
          progressDialogRef.componentInstance.status = 'success';
        });
    }

    if (this.currentGroupOptionValue === groupOptions.New) {
      combineLatest([this.createGroup(), timer(0, this.showProgressMinTimeMs).pipe(skip(1), take(1))])
        .pipe(
          catchError(() => {
            progressDialogRef.close();
            this.isError = true;

            return of(null);
          }),
          switchMap(([groupId]) => {
            if (groupId) {
              return from(this.getTransactionsByGroupId(groupId));
            }

            return of(null);
          }),
          switchMap((transactions) => {
            // it creates en empty transaction with group => get it and update with given data
            if (transactions && transactions[0]) {
              return this.updateTransaction(transactions[0]);
            }

            return of(null);
          }),
        )
        .subscribe(() => {
          this.closeMenu.emit();
          progressDialogRef.componentInstance.status = 'success';
        });
    }
  }

  protected onTransactionTypeChange(): void {
    if (
      this.specificValuesForTransactionTypes &&
      this.specificValuesForTransactionTypes.has(this.selectedTransactionType)
    ) {
      const specificData = this.specificValuesForTransactionTypes.get(this.selectedTransactionType);

      if ('price' in specificData) {
        this.price = specificData.price;
      }

      if ('qty' in specificData) {
        this.qty = specificData.qty;
      }
    }
  }

  protected onGroupOptionChange(): void {
    this.updateSizeAndPosition();
  }

  protected onChangeShowArchivedTrades(): void {
    // set selected group to null if it is in this section and section is closed
    if (this.selectedGroup && this.showArchivedTrades) {
      const isSelectedGroupInArchivedSection = this.archivedGroups.some((item) => item.id === this.selectedGroup.id);

      if (isSelectedGroupInArchivedSection) {
        this.selectedGroup = null;
      }
    }

    this.showArchivedTrades = !this.showArchivedTrades;
    this.updateSizeAndPosition();
  }

  protected onChangeShowActiveTrades(): void {
    // set selected group to null if it is in this section and section is closed
    if (this.selectedGroup && this.showActiveTrades) {
      const isSelectedGroupInActiveSection = this.activeGroups.some((item) => item.id === this.selectedGroup.id);

      if (isSelectedGroupInActiveSection) {
        this.selectedGroup = null;
      }
    }

    this.showActiveTrades = !this.showActiveTrades;
    this.updateSizeAndPosition();
  }

  protected onKeyDown(type: 'up' | 'down', field: 'qty' | 'price', step: number, max: number): void {
    if (this[field] === null) {
      this[field] = 0;

      return;
    }

    if (type === 'up') {
      const newValue = round(this[field] + step, 2);
      this[field] = newValue <= max ? newValue : this[field];
    }

    if (type === 'down') {
      const newValue = round(this[field] - step, 2);
      this[field] = newValue >= 0 ? newValue : this[field];
    }
  }

  protected searchSymbol(): void {
    this.searchString = this.searchString.toUpperCase();
    const searchStringLength = this.searchString.trim().length;

    if (this.searchString && searchStringLength > 0) {
      this.filteredActiveGroups = this.activeGroups.filter((item) => {
        return item.symbol.trim() === this.searchString.trim();
      });
      this.filteredArchivedGroups = this.archivedGroups.filter((item) => {
        return item.symbol.trim() === this.searchString.trim();
      });
    } else {
      this.filteredActiveGroups = this.activeGroups.filter((item) => {
        return item.symbol.trim() === this.data.symbol.trim();
      });
      this.filteredArchivedGroups = this.archivedGroups.filter((item) => {
        return item.symbol.trim() === this.data.symbol.trim();
      });
    }

    if (
      this.selectedGroup &&
      !this.filteredActiveGroups.some((item) => item.id === this.selectedGroup.id) &&
      !this.filteredArchivedGroups.some((item) => item.id === this.selectedGroup.id)
    ) {
      this.selectedGroup = null;
    }

    this.updateSizeAndPosition();
  }

  protected onChangeVisibility({ visible }: { visible: boolean }, type: 'top' | 'bottom'): void {
    if (type === 'top') {
      this.isGroupsListTopVisible = visible;
    }

    if (type === 'bottom') {
      this.isGroupsListBottomVisible = visible;
    }
  }

  protected trackByFn(index: number, item: { id?: string | number }): string | number {
    return item.id ?? index;
  }

  private updateSizeAndPosition(): void {
    setTimeout(() => {
      this.contentResized.emit();
    }, 200);
  }

  private updateGroupsLists(): void {
    this.activeGroups = [...this.allGroups].filter((item) => {
      return item.symbol.length > 0 && item.type === TradingLogGroupType.Active;
    });
    this.archivedGroups = [...this.allGroups].filter((item) => {
      return item.symbol.length > 0 && item.type === TradingLogGroupType.Archived;
    });

    this.searchSymbol();
  }

  private createGroup(): Observable<string> {
    const accountId = this.selectedAccount === this.notDefinedId ? undefined : this.selectedAccount;
    const strategyId = this.selectedStrategy === this.notDefinedId ? undefined : this.selectedStrategy;

    const newGroup = {
      id: Guid.newGuid().toString(),
      type: TradingLogGroupType.Active,
      account_id: accountId,
      strategy_id: strategyId,
      is_expanded: true,
      symbol: this.data.symbol,
      order: 0,
      has_error: false,
      isEmpty: true,
    };

    return this.tradingLogStreamingService.addEmptyGroup(newGroup, TradingLogGroupType.Active);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createTransaction(group: Partial<TradingLogGroupModel>): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { symbol, ...transactionData } = this.data;
    const emptyTransaction = this.tradingLogStreamingService.addEmptyTransaction(group.id);
    const filledTransaction = {
      ...emptyTransaction,
      ...transactionData,
      type: this.selectedTransactionType,
      price: this.price,
      qty: this.qty,
    };

    const command = new TLBathTransactionsCommand();
    command.appendTransaction(filledTransaction);

    return this.tradingLogStreamingService.sendBunchTransactionCommand(command);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private updateTransaction(existingTransaction: Partial<TradingLogTransactionModel>): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { symbol, ...transactionData } = this.data;
    const filledTransaction = {
      ...existingTransaction,
      ...transactionData,
      type: this.selectedTransactionType,
      price: this.price,
      qty: this.qty,
    };

    const command = new TLBathTransactionsCommand();
    command.appendTransaction(filledTransaction);

    return this.tradingLogStreamingService.sendBunchTransactionCommand(command);
  }

  private async getTransactionsByGroupId(groupId: string): Promise<ReadonlyArray<Partial<TradingLogTransactionModel>>> {
    return new Promise((resolve) => {
      this.tradingLogStreamingService.transactionsMap$.pipe(skip(1), take(1)).subscribe((transactionsMap) => {
        const allTransactions = Array.from(transactionsMap.values()).flat();
        const transactionFilteredByGroupId = allTransactions.filter((item) => item.group_id === groupId);

        resolve(transactionFilteredByGroupId);
      });

      this.tradingLogStreamingService.sendCommand(new TLRequestTransactionsCommand(groupId));
    });
  }

  private sortGroups(groups: ReadonlyArray<IExtendedTradingLogGroup>): ReadonlyArray<IExtendedTradingLogGroup> {
    return groups
      .map((group) => ({ group, date: group.min_date }))
      .sort(getDateComparerDesc((o) => (o.date ? moment(o.date, MomentDateTimeFormats.ServerDate) : null)))
      .map((o) => o.group);
  }

  private resetComponentState(): void {
    this.data = {};
    this.searchString = '';
    this.activeGroups = [];
    this.archivedGroups = [];
    this.filteredActiveGroups = [];
    this.filteredArchivedGroups = [];
    this.qty = null;
    this.price = null;
    this.selectedTransactionType = null;
    this.currentGroupOptionValue = groupOptions.New;
    this.specificValuesForTransactionTypes = null;
  }

  private getTradingLogTransactionTypesFullNames(): Record<TradingLogTransactionType, string> {
    return Object.values(TradingLogTransactionType).reduce(
      (acc, type) => {
        acc[type] = this.tradingLogLogicService.getTransactionDisplayName(type);
        return acc;
      },
      {} as Record<TradingLogTransactionType, string>,
    );
  }
}
